import {AfterViewInit, Component, Input, OnInit, ViewChild} from "@angular/core";
import {Media} from "../../../shared/model/media/Media";
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-product-image-gallery",
	templateUrl: "./product-image-gallery.component.html",
	styleUrls: ["./product-image-gallery.component.scss"]
})
export class ProductImageGalleryComponent implements OnInit, AfterViewInit {
	@ViewChild('gallery', { static: true }) carousel: NgbCarousel;

	@Input()
	public images: Array<Media>;

	constructor() {
	}

	ngOnInit() {
		if (this.images && this.images.length && this.images[0].position !== undefined) {
			this.images = this.images.sort((a, b) => {
				if (a.position < b.position) {
					return -1;
				}
				if (a.position > b.position) {
					return 1;
				}
				return 0;
			});
		}
	}


	ngAfterViewInit(): void {
		// $("#zoom_01").elevateZoom({
		// 	constrainType: "height",
		// 	constrainSize: 274,
		// 	zoomType: "lens",
		// 	containLensZoom: true,
		// 	cursor: "pointer"
		// });

	}

	randomDildo() {
		return "../../../assets/images/sample-3.jpg";
	}

	isSingleImageGallery(): boolean {
		return this.images.length === 1;
	}

}
