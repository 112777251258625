<ngb-carousel
	id="gallery"
	#gallery
	[ngClass]="{ 'single-image-gallery': isSingleImageGallery() }"
	[interval]="0"
	[showNavigationArrows]="false"
>
	<ng-template ngbSlide *ngFor="let image of images">
		<img [src]="image.urlLarge" loading="lazy" />
	</ng-template>
</ngb-carousel>

<!-- Custom Previous Button -->
<button class="custom-prev-btn" (click)="carousel.prev()">
	<i class="fi fi-rr-angle-left"></i>
</button>

<!-- Custom Next Button -->
<button class="custom-next-btn" (click)="carousel.next()">
	<i class="fi fi-rr-angle-right"></i>
</button>
